var render = function render(){var _vm=this,_c=_vm._self._c;return _c('YLayoutBodyMenu',{attrs:{"menu":[
    {title:'基础配置',path:'/shop/plugin/partnership/conf'},
    {title:'升级商品',path:'/shop/plugin/partnership/upgrade-goods'},
    {title:'用户列表',path:'/shop/plugin/partnership/user'},
    {title:'股东列表',path:'/shop/plugin/partnership/shareholder'},
    {title:'限期活动',path:'/shop/plugin/partnership/active'},
]}},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }