<template>
   <YLayoutBodyMenu :menu="[
       {title:'基础配置',path:'/shop/plugin/partnership/conf'},
       {title:'升级商品',path:'/shop/plugin/partnership/upgrade-goods'},
       {title:'用户列表',path:'/shop/plugin/partnership/user'},
       {title:'股东列表',path:'/shop/plugin/partnership/shareholder'},
       {title:'限期活动',path:'/shop/plugin/partnership/active'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>